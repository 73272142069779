import { render, staticRenderFns } from "./Purpose.vue?vue&type=template&id=9d2ab6ba&"
import script from "./Purpose.vue?vue&type=script&lang=ts&"
export * from "./Purpose.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports